html {
  scroll-behavior: smooth;   
}


*{ font-family: var(--pFont); }
h1{ color: var(--h1Color); font-family: var(--hFont); }
h2{ color: var(--h2Color); font-family: var(--hFont); }
h3{ color: var(--h3Color); font-family: var(--hFont); }
h4, h5, h6{ color: var(--h4Color); font-family: var(--hFont); }
ul, ol, p{ color: var(--pColor); font-family: var(--pFont); }
a{ color: var(--anchorColor); text-decoration: none; font-family: var(--pFont); }


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; 
}

.width10 {
  width: 10%;
}

.width20 {
  width: 20%;
}

.width30 {
  width: 30%;
}

.width40 {
  width: 40%;
}

.width50 {
  width: 50%;
}

.width60 {
  width: 60%;
}

.width70 {
  width: 70%;
}

.width80 {
  width: 80%;
}

.width90 {
  width: 90%;
}
.width96 {
  width: 96%;
}

.width100 {
  width: 100%;
}

.nomargin {
  margin: 0;
}

.nopadding {
  padding: 0;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.textCenter {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.flex {
  display: flex;
}

.flexWrap {
  flex-wrap: wrap;
}

.alignCenter {
  align-items: center;
}

.justifyCenter, .justifycenter {
  justify-content: center;
}
.justifyLeft {
  justify-content: left;
}
.justifyRight {
  justify-content: right;
}

.flexColumn {
  flex-direction: column;
}

.grid {
  display: grid;
}

.grid2 {
  grid-template-columns: 1fr 1fr;
}

.grid3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.gridSmallBig {
  grid-template-columns: 60px calc(100% - 70px);
}

.gap10 {
  gap: 10px;
}

.gap20 {
  gap: 20px;
}

.gap30 {
  gap: 30px;
}

.pchide {
  display: none;
}

.mobhide {
  display: block;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.floatCenter {
  margin: 0 auto;
}

.maxWidth1100 {
  max-width: 1100px;
}

.maxWidth1300 {
  max-width: 1300px;
}

.maxWidth1500 {
  max-width: 1500px;
}

.maxWidth600 {
  max-width: 600px;
}

.maxWidth650 {
  max-width: 650px;
}

.maxWidth700 {
  max-width: 700px;
}
.gap40 {
  gap: 40px;
}

.gap50 {
  gap: 50px;
}

.gap60 {
  gap: 60px;
}

.gap70 {
  gap: 70px;
}

.gap80 {
  gap: 80px;
}

.gap90 {
  gap: 90px;
}

.gap100 {
  gap: 100px;
}

a {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.gap50 {
  gap: 50px;
}

.gap70 {
  gap: 70px;
}

.gap80 {
  gap: 80px;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyBetween {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}


.myMenu{
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #FFFFFF;
}

.alerts{
  width: calc(90% - 20px);
  padding: 10px;
  border-radius: 5px;
  background-color: red;
  max-width: 500px;
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.insideAlert{
  display: grid;
  grid-template-columns: 30px auto 30px;
  align-items: center;
}
.closeAlert{
  color: #FFFFFFAA;
  cursor: pointer;
  display: flex;
  justify-content: right;
}
.alertIcon{
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  justify-content: left;
}
.alertInfo{
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
}
.successAlert{
  background-color: #34AA44;
}
.infoAlert{
  background-color: #1665D8;
}
.warningAlert{
  background-color: #F6AB2F;
}
.errorAlert{
  background-color: #E6492D;
}
.cartArea{
  position: relative;
}
.priceAreaLoading{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--themeLightColor);
  border-radius: 5px;
  z-index: 9;
}
.priceAreaLoadingCon{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.priceAreaLoadingCon .loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}
.priceAreaLoadingCon .loader::after,
.priceAreaLoadingCon .loader::before {
  content: '';  
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 4px solid var(--btnColor);
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}
.priceAreaLoadingCon .loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.successPage{
  padding: 100px 0;
  background-position: center!important;
  background-size: cover!important;
}
.successPage img{
  width: 150px;
  height: auto;
  object-fit: contain;
}
.successHeading{
  font-size: 20px;
  font-weight: 600;
  padding: 20px;
}
.successPara{
  font-size: 14px;
  line-height: 25px;
  padding: 10px;
}
.successPageCon{
  width: 90%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.exploreBtn{
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--btnColor);
  color: var(--btnColorText);
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
}

.htmlFooter{
  padding: 20px;
}

.line1 {
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.line2 {
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.line3 {
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.line4 {
display: -webkit-box;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;  
overflow: hidden;
}

  .loaderAll {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #FFF #FFF transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loaderAll::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent #FF3D00 #FF3D00;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
      
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  .mainLoader{
      position:absolute;
      width:100%;
      height:100%;
      display:flex;
      align-items:center;
      justify-content:center;
      z-index:-1;
  }
@media only screen and (max-width: 900px) {

  .width10,
  .width20,
  .width30,
  .width40,
  .width50,
  .width60,
  .width70,
  .width80 {
      width: 100%;
  }

  .grid2,
  .grid3,
  .grid4,
  .grid5 {
      grid-template-columns: 1fr;
  }
  .mobgrid2{
      grid-template-columns: 1fr 1fr;
  }
  .mobgrid3{
      grid-template-columns: 1fr 1fr 1fr;
  }
  .mobgrid4{
      grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .mobgrid5{
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .pchide {
      display: block;
  }

  .mobhide {
      display: none;
  }
}