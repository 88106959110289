.loginReact{
    padding: 20px;
    background-color: var(--themeBg1);
}
.loginBox{
    padding: 20px 10px;
    background-color: #FFFFFF;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
}
.inputBox label{
    font-weight: 600;
    padding-bottom: 5px;
}
.inputF{
    padding: 10px;
    width: -webkit-fill-available;
    border: 1px solid var(--themeColor);
    border-radius: 5px;
    background-color: var(--themeBg1);
    margin-top:10px;
}
.btnClas{
    width: -webkit-fill-available;
    padding:10px;
    background-color:var(--btnColor);
    color:var(--btnTextColor);
    border:0;
    margin-top:20px;
    border-radius: 5px;
    cursor: pointer;
}
.signupLogin{
    padding: 20px;
    padding-bottom: 0;
}
.signupLogin a, .inputBox a{
    font-weight: 600;
    font-size: 14px;
    color: var(--themeColor);
}

.strongpass ul{
    list-style-type: none;
}
.strongpass{
    margin-top: 20px;
    /* width: 90%;
    max-width: 350px; */
    /* margin: 0 auto; */
}
.strongpass ul li{
    /* width: calc(50% - 10px); */
    float: left;
    margin: 5px;
    text-align: left;
    font-size:12px;
    color: #bd5254;
}
.otpBOXES {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
}
.otpBOXES div{
    /* justify-content: center; */
}
.otpBOXES input{
    margin: 0;
    /* width: -webkit-fill-available!important; */
}
.resendOTP{
    padding: 10px;
}
.resendOTP span{
    font-weight: 600;
    color: var(--themeColor);
    cursor: pointer;
}
